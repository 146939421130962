import defaultConf from './default';
import prophrConf from './prophr';
import sportConf from './sport';

import logoMatch from '../../../images/logoMatch.svg';
import logoPulse from '../../../images/logoPulse.svg';
import logo from '../../../images/logo.svg';

import logoMatchProphr from '../../../images/logo-prophr.svg';
import logoPulseProphr from '../../../images/logo-prophr.svg';
import logoProphr from '../../../images/logo-prophr.svg';

const getFranchiseConf = () => {

  const host = location.host;
  switch (host) {
    case 'app.prophr.io':
      return {
        ...prophrConf,
        logo: logoMatchProphr,
        logoPulse: logoPulseProphr,
        logoMatch: logoProphr
      };
    case 'app.examiz.asia':
      return {
        ...defaultConf,
        logo,
        logoPulse,
        logoMatch,
        apiUrl: 'https://api.examiz.asia'
      }
    case 'sport.examiz.net':
      return {
        ...sportConf,
        logo,
        logoPulse,
        logoMatch
      };
    default:
      return {
        ...defaultConf,
        logo,
        logoPulse,
        logoMatch
      };
  }
}

export default getFranchiseConf();